<template>
	<div class="container is-fluid px-0 main">
		<section class="hero has-background-dark mb-6">
			<div class="hero-body">
				<div class="is-flex is-align-items-center mb-3">
					<svg
						version="1.1"
						xml:space="preserve"
						height="80"
						xmlns="http://www.w3.org/2000/svg"
						xmlns:xlink="http://www.w3.org/1999/xlink"
						viewBox="0 0 160 160"
					>
						<defs>
							<symbol id="hexa">
								<path
									d="m25,5 40,0 20,34.64 -20,34.64 -40,0 -20,-34.64z"
									fill="teal"
									stroke="teal"
									stroke-width="6"
									stroke-linejoin="round"
								/>
							</symbol>
						</defs>
						<use xlink:href="#hexa" />
						<use xlink:href="#hexa" transform="translate(0 80)" />
						<use xlink:href="#hexa" transform="translate(69 40)" />
					</svg>
					<h1 class="my-0 mx-3 title is-1 has-text-white has-text-weight-normal">DRAWBIN</h1>
				</div>
				<h3 class="title is-4 has-text-white has-text-weight-normal">Transform your text in hexagonal binary-based drawing</h3>
			</div>
		</section>
		<div class="container is-fluid">
			<div class="columns">
				<div class="column is-9">
					<div class="field is-horizontal">
						<div class="field-label is-normal">
							<label class="label">Your Text</label>
						</div>
						<div class="field-body">
							<div class="field">
								<div class="control">
									<input class="input" type="text" v-model="input_text" @input="drawLines" ref="inputText" />
								</div>
							</div>
						</div>
					</div>
					<div class="field is-horizontal">
						<div class="field-label is-normal">
							<label class="label"
								>Binary Result
								<span class="icon" title="Hexagon-based structure with 0 = going left and 1 = going right">
									<i class="fas fa-question-circle"></i> </span
							></label>
						</div>
						<div class="field-body">
							<div class="field">
								<div class="control">
									<textarea
										class="textarea has-fixed-size has-background-grey-lighter"
										readonly
										id="binary"
										v-model.trim="strToBin"
										ref="binaryResult"
									></textarea>
								</div>
							</div>
						</div>
					</div>
					<div class="field is-horizontal">
						<div class="field-label">
							<label class="label">Line Width</label>
						</div>
						<div class="field-body">
							<div class="field is-narrow">
								<div class="control">
									<label class="radio mr-3" v-for="(i, j) in 10" :key="j">
										<input type="radio" name="lineWidth" v-model="selected" :value="i" @change="drawLines" />
										{{ i }}
									</label>
								</div>
							</div>
						</div>
					</div>
					<div class="field is-horizontal">
						<div class="field-label">
							<!-- Left empty for spacing -->
						</div>
						<div class="field-body">
							<div class="field">
								<div class="control">
									<button class="button is-dark" @click="clear">Try Again !</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="column is-3" v-if="input_text.length">
					<p class="text-center mb-1">Background Color</p>
					<color-picker
						:isWidget="true"
						:disableAlpha="true"
						:disableHistory="true"
						v-model:pureColor="bgColor"
						@update:pureColor="drawLines"
					>
					</color-picker>
				</div>
			</div>
			<hr />
			<div class="columns" v-show="input_text.length">
				<div class="column is-2 mt-4">
					<div class="columns is-multiline" id="addedCPs" :bind="checkInput">
						<div class="column is-4 has-text-centered mb-4" v-for="(picker, k) in pickers" :key="k">
							<p class="mb-1">word #{{ k + 1 }}</p>
							<color-picker
								:disableAlpha="true"
								:disableHistory="true"
								:pickerContainer="addedCPs"
								v-model:pureColor="picker.color"
								@update:pureColor="drawLines"
							>
							</color-picker>
						</div>
					</div>
				</div>
				<div class="column is-10">
					<canvas class="is-block mx-auto mt-4" id="drawCanvas" ref="draw_canvas"></canvas>
				</div>
			</div>
		</div>
		<footer class="has-text-centered mt-4">
			<p>Made with HTML5 Canvas, VueJS &amp; Bulma</p>
			<em>© 2023 - Loïc Parent</em>
		</footer>
	</div>
</template>

<script>
/* eslint-disable */
export default {
	name: 'App',
	data() {
		return {
			input_text: '',
			selected: 5,
			firstPicker: null,
			pickers: [],
			xMin: undefined,
			xMax: undefined,
			yMin: undefined,
			yMax: undefined,
			canvas: undefined,
			ctx: undefined,
			bgColor: '#ffffff',
		};
	},
	computed: {
		strToBin() {
			let output = [];
			for (let i = 0; i < this.input_text.length; i++) {
				let bin = this.input_text[i].charCodeAt().toString(2);
				output.push(Array(8 - bin.length + 1).join('0') + bin);

				// remove the space character from the array
				let index = output.indexOf('00100000');
				if (index > -1) {
					output.splice(index, 1);
				}
			}
			return output.join(' ');
		},

		wordCount() {
			return this.input_text.split(' ').length;
		},
		checkInput() {
			for (let char = 0; char < this.input_text.length; char++) {
				if (this.input_text[char] === ' ') {
					this.pickers.push({ color: this.rndRGB() });
				}
			}
			this.pickers.splice(this.wordCount);
			return true;
		},
	},
	mounted() {
		this.canvas = this.$refs.draw_canvas;
		this.ctx = this.canvas.getContext('2d');
		this.pickers.push({ color: this.rndRGB() });
	},
	methods: {
		rndRGB() {
			return '#' + Math.floor(Math.random() * 16777215).toString(16);
		},
		charToBin(c) {
			let bin = c.charCodeAt().toString(2);
			return Array(8 - bin.length + 1).join('0') + bin;
		},
		clear() {
			this.input_text = '';
			this.pickers.splice(1);
			this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
			this.canvas.height = 0;
			this.canvas.width = 0;
			this.$refs.inputText.focus();
		},

		degToRad(deg) {
			return (Math.PI * deg) / 180;
		},
		path: function (q, r, s, t) {
			this.ctx.beginPath();
			this.ctx.lineCap = 'round';
			this.ctx.moveTo(q, r);
			this.ctx.lineTo(s, t);
			this.ctx.stroke();
			this.ctx.closePath();
		},
		drawLines() {
			this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
			this.drawHive(); // draw once to get x, y coordinates
			this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height); // clear drawing
			this.canvas.width = 50 + this.xMax - this.xMin;
			this.canvas.height = 50 + this.yMax - this.yMin;
			this.ctx.fillStyle = this.bgColor;
			this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);
			this.ctx.translate(20 - this.xMin, 20 - this.yMin); // translate the origin point

			this.drawHive(); // draw and display
			this.ctx.setTransform(1, 0, 0, 1, 0, 0); // reset the origin point to default
		},
		drawHive: function () {
			let x0 = 500;
			let y0 = 400;
			let a = 40 * Math.cos(this.degToRad(60));
			let b = 40 * Math.cos(this.degToRad(30));
			let x = x0 + 40;
			let y = y0;
			let xCoordinates = [x0, x];
			let yCoordinates = [y0];

			let color = this.pickers[0].color;
			this.ctx.strokeStyle = color;
			this.ctx.lineWidth = this.selected;
			let space_count = 0;
			this.path(x0, y0, x0 + 40, y0);
			for (let c = 0; c < this.input_text.length; c++) {
				if (this.input_text[c] === ' ') {
					space_count++;
					this.ctx.strokeStyle = this.pickers[space_count].color;
				} else {
					let binCharToken = this.charToBin(this.input_text[c]);

					for (let i = 0; i < binCharToken.length; i++) {
						if (y === y0) {
							if (x > x0) {
								if (binCharToken[i] === '0') {
									this.path(x, y, x + a, y - b);
									x0 = x;
									y0 = y;
									x = x + a;
									y = y - b;
								} else if (binCharToken[i] === '1') {
									this.path(x, y, x + a, y + b);
									x0 = x;
									y0 = y;
									x = x + a;
									y = y + b;
								}
							} else if (x < x0) {
								if (binCharToken[i] === '0') {
									this.path(x, y, x - a, y + b);
									x0 = x;
									y0 = y;
									x = x - a;
									y = y + b;
								} else if (binCharToken[i] === '1') {
									this.path(x, y, x - a, y - b);
									x0 = x;
									y0 = y;
									x = x - a;
									y = y - b;
								}
							}
						} else if (y > y0) {
							if (x > x0) {
								if (binCharToken[i] === '0') {
									this.path(x, y, x + 40, y);
									x0 = x;
									y0 = y;
									x = x + 40;
								} else if (binCharToken[i] === '1') {
									this.path(x, y, x - a, y + b);
									x0 = x;
									y0 = y;
									x = x - a;
									y = y + b;
								}
							} else if (x < x0) {
								if (binCharToken[i] === '0') {
									this.path(x, y, x + a, y + b);
									x0 = x;
									y0 = y;
									x = x + a;
									y = y + b;
								} else if (binCharToken[i] === '1') {
									this.path(x, y, x - 40, y);
									x0 = x;
									y0 = y;
									x = x - 40;
								}
							}
						} else if (y < y0) {
							if (x > x0) {
								if (binCharToken[i] === '0') {
									this.path(x, y, x - a, y - b);
									x0 = x;
									y0 = y;
									x = x - a;
									y = y - b;
								} else if (binCharToken[i] === '1') {
									this.path(x, y, x + 40, y);
									x0 = x;
									y0 = y;
									x = x + 40;
								}
							} else if (x < x0) {
								if (binCharToken[i] === '0') {
									this.path(x, y, x - 40, y);
									x0 = x;
									y0 = y;
									x = x - 40;
								} else if (binCharToken[i] === '1') {
									this.path(x, y, x + a, y - b);
									x0 = x;
									y0 = y;
									x = x + a;
									y = y - b;
								}
							}
						}
						xCoordinates.push(x);
						yCoordinates.push(y);
					}
				}
				this.xMax = Math.max.apply(null, xCoordinates);
				this.yMax = Math.max.apply(null, yCoordinates);
				this.xMin = Math.min.apply(null, xCoordinates);
				this.yMin = Math.min.apply(null, yCoordinates);
			}
		},
	},
};
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Sedgwick+Ave&display=swap');
.main {
	font-family: 'Sedgwick Ave', Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}

#drawCanvas {
	max-width: 100%;
	max-height: 700px;
}
.cpw_container {
	margin: 0 auto;
}
#bgColorInput {
	max-width: 200px;
	margin: 0 auto;
}
textarea {
	overflow-y: hidden !important;
}
input,
button,
textarea {
	font-family: 'Sedgwick Ave', Helvetica, Arial, sans-serif !important;
}
.input-group-text {
	min-width: 40px;
}
</style>
